import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from "./userStore";

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {
            title : 'Wunschkontrollschilder | eSchild',
            enterClass: 'fadeinright animation-duration-500 animation-ease-in',
            leaveClass: 'fadeoutright animation-duration-500 animation-ease-in',
        },
        component: () => import('../components/PlatePage.vue')
    },
    {
        path: '/reservation',
        name: 'Reservation',
        meta: {
            title : 'Reservation | eSchild',
            enterClass: 'fadeinright animation-duration-500 animation-ease-in',
            leaveClass: 'fadeoutright animation-duration-500 animation-ease-in',
        },
        component: () => import('../components/ReservationPage.vue')
    },
    {
        path: '/reservation/confirmation',
        name: 'ReservationConfirmation',
        meta: {
            title : 'Reservation bestätigt | eSchild',
            enterClass: 'fadeinright animation-duration-500 animation-ease-in',
            leaveClass: 'fadeoutright animation-duration-500 animation-ease-in',
        },
        beforeEnter: (to, from, next ) => {
            if (to.name != "ReservationConfirmation" || from.name != "Reservation") next({ name: 'Reservation' })
            else next()
        },
        component: () => import('../components/ReservationConfirmationPage.vue')
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title : 'Login | eSchild',
            enterClass: 'fadeinright animation-duration-500 animation-ease-in',
            leaveClass: 'fadeoutright animation-duration-500 animation-ease-in',
        },
        beforeEnter: (to, from, next ) => {
            const userStore = useUserStore();
            if (userStore.isAuthenticated) next({ name: 'Administration' })
            else next()
        },
        component: () => import('../components/LoginPage.vue')
    },
    {
        path: '/administration',
        name: 'Administration',
        meta: {
            title : 'Administration | eSchild',
            enterClass: 'fadein animation-duration-500 animation-ease-in',
            leaveClass: 'fadeout animation-duration-500 animation-ease-in',
            requiresAuth: true
        },
        component: () => import('../components/AdministrationPage.vue')
    },
    {
        path: '/terms',
        name: 'Terms',
        meta: {
            title : 'Reglement | eSchild',
            enterClass: 'fadein animation-duration-500 animation-ease-in',
            leaveClass: 'fadeout animation-duration-500 animation-ease-in',
        },
        component: () => import('../components/TermsPage.vue')
    },
    {
        path: '/subscription',
        name: 'Subscription',
        meta: {
            title : 'Schildalarm | eSchild',
            enterClass: 'fadeinup animation-duration-500 animation-ease-in',
            leaveClass: 'fadeoutdown animation-duration-500 animation-ease-in',
        },
        component: () => import('../components/SubscriptionPage.vue')
    },
    {
        path: '/subscription/confirmation',
        name: 'SubscriptionConfirmation',
        meta: {
            title : 'Schildalarm bestätigt | eSchild',
            enterClass: 'fadeinright animation-duration-500 animation-ease-in',
            leaveClass: 'fadeoutright animation-duration-500 animation-ease-in',
        },
        beforeEnter: (to, from, next ) => {
            if (to.name != "SubscriptionConfirmation" || from.name != "Subscription") next({ name: 'Subscription' })
            else next()
        },
        component: () => import('../components/SubscriptionConfirmationPage.vue')
    },
    {
        path: '/confirm/:guid',
        name: 'VerifyEmail',
        meta: {
            title : 'E-Mail bestätigt | eSchild',
            enterClass: 'fadeinup animation-duration-500 animation-ease-in',
            leaveClass: 'fadeoutdown animation-duration-500 animation-ease-in',
        },
        component: () => import('../components/EmailConfirmation.vue')
    },
    {
        path: '/imprint',
        name: 'Imprint',
        meta: {
            title : 'Impressum | eSchild',
            enterClass: 'fadeinup animation-duration-500 animation-ease-in',
            leaveClass: 'fadeoutdown animation-duration-500 animation-ease-in',
        },
        component: () => import('../components/ImprintPage.vue')
    },
    {
        path: '/about',
        name: 'About',
        meta: {
            title : 'Über | eSchild',
            enterClass: 'fadeinup animation-duration-500 animation-ease-in',
            leaveClass: 'fadeoutdown animation-duration-500 animation-ease-in',
        },
        component: () => import('../components/AboutPage.vue')
    },
    {
        path: '/logout',
        name: 'Logout',
        beforeEnter: () => {
            const userStore = useUserStore();
            userStore.emptyLogin();
            return "/"
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        meta: {
            title : 'Seite nicht gefunden | eSchild',
            enterClass: 'fadeinup animation-duration-500 animation-ease-in',
            leaveClass: 'fadeoutdown animation-duration-500 animation-ease-in',
        },
        component: () => import('../components/NotFoundPage.vue')
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition)
            return savedPosition
        else
            return { left: 0, top: 0 };
    }
});

router.beforeEach((to) => {
const userStore = useUserStore();
    if(to.matched.some(record => record.meta.requiresAuth)) {
      if (!userStore.isAuthenticated) {
        return "/login"
      }
    }
    //set title
    document.title = to.meta.title;
  })

export default router;