import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { some } from "lodash";

export const useCartStore = defineStore('shoppingCart', {
    state: () => ({
        plates: useStorage('plates', [])
    }),
    getters: {
        getAllPlates() {
            return this.plates;
        },
        isEmpty(){
            return this.plates.length <= 0;
        },
        count(){
            if (this.plates.length <= 0) return null;
            return this.plates.length;
        },
        totalprice(){
            let total = 0.00;
            this.plates.forEach(plate => {
                total += plate.price;
            });
            return total;
        }
    },
    actions: {
        addplate(plate) {
            if (!some(this.plates, plate)) {
                this.plates.push(plate);
              }
        },
        removeplate(plate) {
            //this.plates = _.filter(this.plates, () => {return !_.some(this.plates, plate) });
            this.plates = this.plates.filter(function(el) { return el.id != plate.id; }); 
        },
        emptyCart(){
            this.plates = [];
        },
    }
  })