<template>
  <div class="w-full surface-section px-4 md:px-6 lg:px-8" v-if="$route.name != 'Administration'">
    <div class="surface-section pt-6 flex flex-column sm:flex-row align-items-center justify-content-between">
      <div class="flex flex-wrap font-medium">
        <VueDropdown v-model="selectedLanguage" :options="languages" optionLabel="label" optionValue="value" id="langswitcher" @change="this.$i18n.locale = $event.value"/>
        <router-link to="/imprint" v-ripple class="no-underline cursor-pointer block text-700 py-2 px-3 transition-colors transition-duration-150 hover:surface-300 border-round hover:text-900 p-ripple">{{ $t("navigation.imprint") }}</router-link>
        <router-link to="/about" v-ripple class="no-underline cursor-pointer block text-700 py-2 px-3 transition-colors transition-duration-150 hover:surface-300 border-round hover:text-900 p-ripple">{{ $t("navigation.about") }}</router-link>
        <a v-ripple href="https://www.gl.ch/services/datenschutzerklaerung.html/18" target="_blank" class="no-underline cursor-pointer block text-700 py-2 px-3 transition-colors transition-duration-150 hover:surface-300 border-round hover:text-900 p-ripple">{{ $t("navigation.privacypolicy") }}</a>
      </div>
      <div class="mt-3 sm:mt-0"></div>
    </div>
    <p>© {{ new Date().getFullYear() }} Strassenverkehrs- und Schifffahrtsamt des Kantons Glarus</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedLanguage: this.$i18n.locale,
      languages: [
        { label: this.$i18n.t("ger"), value: "de" },
        { label: this.$i18n.t("eng"), value: "en" },
        { label: this.$i18n.t("fre"), value: "fr" },
        { label: this.$i18n.t("ita"), value: "it" },
      ],
    };
  },
};
</script>

<style>
#langswitcher .p-dropdown-label {
  padding: 5px !important;
}
</style>
