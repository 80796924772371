<template>
  <div :class="containerClass">
    <div class="min-h-screen flex flex-column surface-ground">
      <!--  default Toast component for notifications -->
      <VueToast position="bottom-right"/>
      <!-- Insert Header Section -->
      <NavHeader />
      <!-- Load Router Contents into Layout -->
      <div class="layout-content px-0 py-4 md:px-4">
        <router-view v-slot="{ Component, route }">
          <transition mode="out-in" :enter-active-class="route.meta.enterClass" :leave-active-class="route.meta.leaveClass">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
      <!-- Show Footer -->
      <NavFooter />
    </div>
  </div>
</template>

<script>
import NavFooter from "./components/NavFooter.vue";
import NavHeader from "./components/NavHeader.vue";

export default {
  data() {
    return {
      message: null,
      text: null,
    };
  },
  components: {
    NavFooter,
    NavHeader
  },
  watch: {
    $route() {
      const i = this.$route.path.toString().indexOf("/");
      const last = this.$route.path.toString().indexOf("/", i + 1);
      this.parentRoute = this.$route.path.toString().slice(0, last);
    },
  },
    computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-wrapper-dark": this.$appState.dark,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": !this.$primevue.config.ripple,
        },
      ];
    },
  },
};
</script>

<style>
body {
  margin: 0;
  overflow: auto;
  min-width: 400px;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.layout-content {
    min-height: -webkit-calc(100vh - 218px);
    min-height: -moz-calc(100vh - 218px);
    min-height: calc(100vh - 218px);
}

.p-invalid {
  border-color: #e24c4c;
}

/* Hide Arrows InputField: Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide Arrows InputField: Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
