import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { some } from "lodash";

export const useUserStore = defineStore('User', {
    state: () => ({
        token: useStorage('usertoken', []),
        name: useStorage('username', [])
    }),
    getters: {
        isAuthenticated() {
            return this.token.length > 0 && this.name.length > 0;
        }
        
    },
    actions: {
        addLogin(toke, nam) {
            if (!some(this.token, toke)) {
                this.token.push(toke);
              }
              if (!some(this.name, nam)) {
                this.name.push(nam);
              }
        },
        emptyLogin(){
            this.token = [];
            this.name = [];
        },
    }
  })