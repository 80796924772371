import { createApp, reactive } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue';
import router from './middleware/router';

//Pinia (global state management)
import { createPinia } from 'pinia'

//Primevue components
import PrimeVue from 'primevue/config';
import ProgressSpinner from 'primevue/progressspinner';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import SplitButton from 'primevue/splitbutton';
import Toast from 'primevue/toast';
import Checkbox from 'primevue/checkbox';
import OverlayPanel from 'primevue/overlaypanel';
import Divider from 'primevue/divider';
import MultiSelect from 'primevue/multiselect';
import Slider from 'primevue/slider';
import InputNumber from 'primevue/inputnumber';
import ToggleButton from 'primevue/togglebutton';
import Menu from 'primevue/menu';
import ToastService from 'primevue/toastservice';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import Tooltip from 'primevue/tooltip';
import Message from 'primevue/message';
import InlineMessage from 'primevue/inlinemessage';
import Dropdown from 'primevue/dropdown';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';    
import Row from 'primevue/row';      
import Toolbar from 'primevue/toolbar';              
import FileUpload from 'primevue/fileupload';
import Rating from 'primevue/rating';
import Textarea from 'primevue/textarea';
import RadioButton from 'primevue/radiobutton';
import Dialog from 'primevue/dialog';
import InputSwitch from 'primevue/inputswitch';

//Primevue Stylesheets
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css";

//Language Files
import en from './lang/en.json'
import de from './lang/de.json'
import fr from './lang/fr.json'
import it from './lang/it.json'

//Create i18n instance with options
const i18n = createI18n({
    locale: navigator.language.split('-')[0] || 'de', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages: {
        en,
        de,
        it,
        fr
    }
})

//Create app instance
const app = createApp(App);

//Import packages
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.use(i18n);
app.use(router);
app.use(createPinia());

//Set default theme
app.config.globalProperties.$appState = reactive({ theme: `lara-light-indigo`, dark: false });

//Primevue Components
app.component('VueInputText', InputText);
app.component('VueButton', Button);
app.component('VueSplitButton', SplitButton);
app.component('VueToast', Toast);
app.component('VueOverlayPanel', OverlayPanel);
app.component('VueCheckbox', Checkbox);
app.component('VueBadge', Badge);
app.component('VueDivider', Divider);
app.component('VueMenu', Menu);
app.component('VueMultiSelect', MultiSelect);
app.component('VueToggleButton', ToggleButton);
app.component('VueSlider', Slider);
app.component('VueProgressSpinner', ProgressSpinner);
app.component('VueInputNumber', InputNumber);
app.component('VueMessage', Message);
app.component('VueInlineMessage', InlineMessage);
app.component('VueDropdown', Dropdown);
app.component('VueDataTable', DataTable);
app.component('VueColumn', Column);
app.component('VueColumnGroup', ColumnGroup);
app.component('VueRow', Row);
app.component('VueToolbar', Toolbar);
app.component('VueFileUpload', FileUpload);
app.component('VueRating', Rating);
app.component('VueTextarea', Textarea);
app.component('VueRadioButton', RadioButton);
app.component('VueDialog', Dialog);
app.component('VueInputSwitch', InputSwitch);

//Primevue Ripple-Effect and Styleclass
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);
app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);

//Mount 'appcontent' to div with id #app
app.mount('#app');
