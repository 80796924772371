<template>
  <div
    class="surface-overlay py-3 px-6 flex align-items-center justify-content-between relative lg:static"
    style="z-index: 10"
  >
    <div class="mr-0 lg:mr-6">
      <router-link to="/">
        <img
          :src="
            $appState.dark
              ? '/images/Logo-Kanton-Glarus_dark.svg'
              : '/images/Logo-Kanton-Glarus.svg'
          "
          alt="Kanton Glarus Logo"
          style="height: 50px; width: 100%"
      /></router-link>
    </div>
    <!-- Burger-Menu for Mobile -->
    <a
      v-ripple
      class="cursor-pointer block lg:hidden text-700 p-ripple"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        leaveToClass: 'hidden',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-bars text-4xl"></i>
    </a>
    <!-- Menu Start -->
    <div
      class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none"
    >
      <ul
        class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row"
      >
        <li>
          <router-link
            to="/"
            v-ripple
            class="no-underline flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
          >
            <i class="pi pi-ticket mr-2"></i>
            <span>{{ $t("plates") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/terms"
            v-ripple
            class="no-underline flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
          >
            <i class="pi pi-book mr-2"></i>
            <span>{{ $t("navigation.terms") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/subscription"
            v-ripple
            class="no-underline flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
          >
            <i class="pi pi-bell mr-2"></i>
            <span>{{ $t("navigation.subscription") }}</span>
          </router-link>
        </li>
        <li v-if="this.userStore.isAuthenticated">
          <router-link
            to="/administration"
            v-ripple
            class="no-underline flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
          >
            <i class="pi pi-briefcase mr-2"></i>
            <span>{{ $t("navigation.admin") }}</span>
          </router-link>
        </li>
      </ul>
      <ul
        class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none"
      >
        <!-- Set Theme Dark -->
        <li v-if="$appState.dark == false">
          <a
            v-ripple
            @click="changeTheme($event, 'lara-dark-indigo', true)"
            class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
          >
            <i class="pi pi-moon text-base lg:text-2xl mr-2 lg:mr-0"></i>
            <span class="block lg:hidden font-medium">{{
              $t("theme.dark")
            }}</span>
          </a>
        </li>
        <!-- Set Theme Light -->
        <li v-if="$appState.dark == true">
          <a
            v-ripple
            @click="changeTheme($event, 'lara-light-indigo', false)"
            class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
          >
            <i class="pi pi-sun text-base lg:text-2xl mr-2 lg:mr-0"></i>
            <span class="block lg:hidden font-medium">{{
              $t("theme.light")
            }}</span>
          </a>
        </li>
        <!-- Cart -->
        <li>
          <router-link
            to="/reservation"
            v-ripple
            class="no-underline flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
          >
            <i
              class="pi pi-shopping-cart text-base lg:text-2xl mr-2 lg:mr-0"
            ></i>
            <span class="block lg:hidden font-medium">{{
              $t("navigation.shoppingcart")
            }}</span>
            <VueBadge
              :value="this.cartStore.count"
              v-bind:class="{ hidden: this.cartStore.isEmpty }"
              class="transition-all transition-duration-500 fadein"
            ></VueBadge>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useCartStore } from "../middleware/cartStore";
import { useUserStore } from "../middleware/userStore";

export default {
  data() {
    return {
      cartStore: null,
      userStore: null,
      message: null,
      text: null,
    };
  },
  methods: {
    changeTheme(event, theme, dark) {
      let themeElement = document.getElementById("theme-link");
      themeElement.setAttribute(
        "href",
        themeElement.getAttribute("href").replace(this.$appState.theme, theme)
      );
      this.$appState.theme = theme;
      this.$appState.dark = dark;
      event.preventDefault();
    },
  },
  created() {
    this.cartStore = useCartStore();
    this.userStore = useUserStore();

    //change theme based on system setting
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      let themeElement = document.getElementById("theme-link");
      themeElement.setAttribute(
        "href",
        themeElement.getAttribute("href").replace(this.$appState.theme, 'lara-dark-indigo')
      );
      this.$appState.theme = 'lara-dark-indigo';
      this.$appState.dark = true;
    }
  },
};
</script>
